<script setup lang="ts">
import { watch } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  isModalShow: {
    type: Boolean,
    default: false,
  },
  withoutCancel: {
    type: Boolean,
    default: false,
  },
  disableButton: {
    type: Boolean,
    default: false,
  },
  borderBlue: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  withoutClose: {
    type: Boolean,
    default: false,
  },
  withBack: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  saveText: {
    type: String,
    default: "Save",
  },
  cancelText: {
    type: String,
    default: "Cancel",
  },
  size: {
    type: String,
    default: "sm",
  },
  withoutBorder: {
    type: Boolean,
    default: false,
  },
  withoutButton: {
    type: Boolean,
    default: false,
  },
  withoutScroll: {
    type: Boolean,
    default: false,
  },
});
const isModalShow = toRef(props, "isModalShow");
const saveText = toRef(props, "saveText");
const cancelText = toRef(props, "cancelText");
const title = toRef(props, "title");
const size = toRef(props, "size");
const disableButton = toRef(props, "disableButton");
const withBack = toRef(props, "withBack");
const withoutBorder = toRef(props, "withoutBorder");
const withoutScroll = toRef(props, "withoutScroll");

const emit = defineEmits([
  "closeModal",
  "saveAction",
  "handleBack",
  "cancelAction",
  "validate",
]);

const hideModal = () => {
  emit("closeModal");
};
const saveAction = () => {
  emit("saveAction");
};
const cancelAction = () => {
  emit("closeModal");
  emit("cancelAction");
};
const handleBack = () => {
  emit("handleBack");
};
const sizeModal = (size) => {
  if (size === "sm") {
    return "max-w-md";
  } else if (size === "xs") {
    return "max-w-xs";
  } else if (size === "md") {
    return "max-w-lg";
  } else if (size === "lg") {
    return "max-w-xl";
  } else if (size === "xl") {
    return "max-w-3xl";
  }
};
watch(isModalShow, (newValue) => {
  document.body.style.overflow = newValue ? "hidden" : "";
});
</script>
<template>
  <div
    class="fixed inset-0 z-[50] bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
    :class="isModalShow ? 'flex' : 'hidden'"
  />
  <div
    :id="id"
    tabindex="-1"
    class="fixed left-0 right-0 top-0 z-[50] h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-x-hidden overflow-y-hidden md:inset-0"
    :class="isModalShow ? 'flex' : 'hidden'"
  >
    <div class="relative max-h-full w-full p-4" :class="sizeModal(size)">
      <!-- Modal content -->
      <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex items-center rounded-t"
          :class="[
            withBack ? '' : 'justify-between',
            $slots.subheader ? 'p-4 pb-0 md:p-5 md:pb-0' : 'p-4 md:p-5',
            withoutBorder ? '' : 'border-b border-gray-200',
          ]"
        >
          <button v-if="withBack" class="mr-[6px]" @click="handleBack">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.68281 10.0578L8.30781 4.43283C8.42509 4.31556 8.58415 4.24967 8.75 4.24967C8.91585 4.24967 9.07491 4.31556 9.19219 4.43283C9.30946 4.55011 9.37535 4.70917 9.37535 4.87502C9.37535 5.04087 9.30946 5.19993 9.19219 5.31721L4.63359 9.87502L16.875 9.87502C17.0408 9.87502 17.1997 9.94087 17.3169 10.0581C17.4342 10.1753 17.5 10.3343 17.5 10.5C17.5 10.6658 17.4342 10.8247 17.3169 10.942C17.1997 11.0592 17.0408 11.125 16.875 11.125L4.63359 11.125L9.19219 15.6828C9.30946 15.8001 9.37535 15.9592 9.37535 16.125C9.37535 16.2909 9.30946 16.4499 9.19219 16.5672C9.07491 16.6845 8.91585 16.7504 8.75 16.7504C8.58415 16.7504 8.42509 16.6845 8.30781 16.5672L2.68281 10.9422C2.6247 10.8842 2.5786 10.8152 2.54715 10.7394C2.5157 10.6635 2.49951 10.5822 2.49951 10.5C2.49951 10.4179 2.5157 10.3366 2.54715 10.2607C2.5786 10.1848 2.6247 10.1159 2.68281 10.0578Z"
                fill="black"
              />
            </svg>
          </button>
          <h3
            v-if="title"
            class="text-lg font-medium text-gray-900 dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            v-if="!withoutClose"
            type="button"
            class="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            @click="hideModal"
          >
            <IconsX class="h-3 w-3" />
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div :class="$slots.subheader ? 'mt-1 px-4 md:px-5' : ''">
          <slot name="subheader" />
        </div>
        <!-- Modal body -->
        <div
          class="h-auto max-h-[70vh] space-y-4 p-4 md:p-5"
          :class="withoutScroll ? '' : 'overflow-y-auto'"
        >
          <slot name="body" />
        </div>
        <!-- Modal footer -->
        <div
          v-if="!$slots.footer && !withoutButton"
          class="flex items-center justify-between rounded-b p-4 dark:border-gray-600 md:p-5"
          :class="[
            withoutBorder ? '' : 'border-t border-gray-200',
            withoutCancel ? 'grid-cols-1' : 'grid-cols-2',
            fullWidth ? 'gap-2' : '',
          ]"
        >
          <button
            v-if="!withoutCancel"
            type="button"
            class="rounded-lg border bg-white px-5 py-2.5 text-sm font-medium hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
            :class="[
              fullWidth ? 'w-full' : '',
              borderBlue
                ? 'border-[#002D5D] text-[#002D5D]'
                : 'border-gray-200 text-gray-500',
            ]"
            @click="cancelAction()"
          >
            <span v-if="$slots.cancelButton">
              <slot name="cancelButton" />
            </span>
            <span v-else>
              {{ cancelText }}
            </span>
          </button>
          <button
            :id="`submit-${id}`"
            type="button"
            :class="[
              disableButton ? 'flik-button-secondary' : 'flik-button',
              withoutCancel ? 'ml-auto' : '',
              fullWidth ? 'w-full' : '',
            ]"
            :disabled="disableButton"
            @click="disableButton ? {} : saveAction()"
          >
            {{ saveText }}
          </button>
        </div>
        <div
          v-else-if="$slots.footer"
          class="flex items-center justify-between rounded-b p-4 dark:border-gray-600 md:p-5"
          :class="[withoutBorder ? '' : 'border-t border-gray-200']"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
